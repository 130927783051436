const initialState = {
  model: null,
  analytics: null,
  project: null,
  lastSaved: null,
  saving: null,
  refresh: null,
  showApiClient: false,
  showNodeTrash: false
}

const podelReducer = (state = initialState, action) => {
  if (action.type === 'SET_MODEL') {
    return { ...state, model: action.model }
  } else if (action.type === 'SET_ANALYTICS') {
    return { ...state, analytics: action.data }
  } else if (action.type === 'SET_PROJECT') {
    return { ...state, project: action.project }
  } else if (action.type === 'SET_REFRESH') {
    return { ...state, refresh: Math.random() }
  } else if (action.type === 'SET_LAST_SAVED') {
    return { ...state, lastSaved: action.lastSaved }
  } else if (action.type === 'SET_SAVING') {
    return { ...state, saving: action.saving }
  } else if (action.type === 'SET_SHOW_API_CLIENT') {
    return { ...state, showApiClient: action.showApiClient }
  } else if (action.type === 'SET_SHOW_NODE_TRASH') {
    return { ...state, showNodeTrash: action.showNodeTrash }
  } else if (action.type === 'RESET_PODEL') {
    return initialState
  }
  return state
}

export { podelReducer }