import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose, combineReducers } from 'redux'

import { dialogsReducer } from './reducers/dialogs'
import { podelReducer } from './reducers/podel'
import { userReducer } from './reducers/user'
import { pialogsReducer } from './reducers/pialogs'

const composeWithDevTols =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const enhancer = composeWithDevTols(applyMiddleware(thunk))

const reducer = combineReducers({userReducer, podelReducer, dialogsReducer, pialogsReducer})
const store = createStore(reducer, enhancer)
export default store