import * as Sentry from '@sentry/nextjs'

const token = typeof window !== 'undefined' ? localStorage.getItem('token') : null

const initialState = {
  token,
  models: null,
  projects: null,
  modelsAccess: null,
  modelAccess: null,
  paymentMethods: null,
  currentPlan: null,
  plans: null,
  user: null,
  selectedModelId: null
}

const userReducer = (state = initialState, action) => {
  if (action.type === 'SET_USER_TOKEN') {
    return { ...state, token: action.token }
  } else if (action.type === 'SET_MODELS') {
    return { ...state, models: action.models }
  } else if (action.type === 'SET_PROJECTS') {
    return { ...state, projects: action.projects }
  } else if (action.type === 'SET_MODELS_ACCESS') {
    return { ...state, modelsAccess: action.modelsAccess }
  } else if (action.type === 'SET_MODEL_ACCESS') {
    return { ...state, modelAccess: action.modelAccess }
  } else if (action.type === 'SET_PAYMENT_METHODS') {
    return { ...state, paymentMethods: action.paymentMethods }
  } else if (action.type === 'SET_CURRENT_PLAN') {
    return { ...state, currentPlan: action.currentPlan }
  } else if (action.type === 'SET_PLANS') {
    return { ...state, plans: action.plans }
  } else if (action.type === 'SET_USER') {
    Sentry.setUser({ email: action.user.email })
    gtag('set', 'user_properties', { user_id: action.user.id })
    return { ...state, user: action.user }
  } else if (action.type === 'SET_SELECTED_MODEL_ID') {
    return { ...state, selectedModelId: action.selectedModelId }
  } else if (action.type === 'RESET_USER') {
    return initialState
  }
  return state
}

export { userReducer }