const initialState = {
  selectedNode: null,
  selectedEngine: null,
  selectedLink: null,
  showShareDialog: false,
  showShareErrorDialog: false,
  showConfigureServicesDialog: false,
  showDeleteNodeDialog: false,
  showHelpDialog: false,
  showEndpointDialog: false,
  showUpgradeDialog: false,
  showDeleteDiagramDialog: false
}

const pialogsReducer = (state = initialState, action) => {
  if (action.type === 'SET_SELECTED_NODE') {
    return { ...state, selectedNode: action.selectedNode }
  } else if (action.type === 'SET_SELECTED_LINK') {
    return { ...state, selectedLink: action.selectedLink }
  } else if (action.type === 'SET_SELECTED_ENGINE') {
    return { ...state, selectedEngine: action.selectedEngine }
  } else if (action.type === 'SET_SHOW_DELETE_NODE_DIALOG') {
    return { ...state, showDeleteNodeDialog: action.showDeleteNodeDialog }
  } else if (action.type === 'SET_SHOW_SHARE_DIALOG') {
    return { ...state, showShareDialog: action.showShareDialog }
  } else if (action.type === 'SET_SHOW_SHARE_ERROR_DIALOG') {
    return { ...state, showShareErrorDialog: action.showShareErrorDialog }
  } else if (action.type === 'SET_SHOW_CONFIGURE_SERVICES_DIALOG') {
    return { ...state, showConfigureServicesDialog: action.showConfigureServicesDialog }
  } else if (action.type === 'SET_SHOW_HELP_DIALOG') {
    return { ...state, showHelpDialog: action.showHelpDialog }
  } else if (action.type === 'SET_SHOW_ENDPOINT_DIALOG') {
    return { ...state, showEndpointDialog: action.showEndpointDialog }
  } else if (action.type === 'SET_SHOW_UPGRADE_DIALOG') {
    return { ...state, showUpgradeDialog: action.showUpgradeDialog }
  } else if (action.type === 'SET_SHOW_LINK_SETTINGS_DIALOG') {
    return { ...state, showLinkSettingsDialog: action.showLinkSettingsDialog }
  } else if (action.type === 'SET_SHOW_DELETE_DIAGRAM_DIALOG') {
    return { ...state, showDeleteDiagramDialog: action.showDeleteDiagramDialog }
  } else if (action.type === 'RESET_PIALOGS') {
    return initialState
  }
  return state
}

export { pialogsReducer }