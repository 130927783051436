const initialState = {
  isEditing: false,
  selectedAction: null,
  selectedNode: null,
  selectedEngine: null,
  showResponseDialog: false,
  showResponseScreenDialog: false,
  showActionDialog: false,
  showDeleteNodeDialog: false,
  showFacebookSettingsDialog: false,
  showGoogleSettingsDialog: false,
  showBillingSettingsDialog: false,
  showShareDialog: false,
  showNetworkErrorDialog: false
}

const dialogsReducer = (state = initialState, action) => {
  if (action.type === 'SET_IS_EDITING') {
    return { ...state, isEditing: action.isEditing }
  } if (action.type === 'SET_SELECTED_ACTION') {
    return { ...state, selectedAction: action.selectedAction }
  } else if (action.type === 'SET_SELECTED_NODE') {
    return { ...state, selectedNode: action.selectedNode }
  } else if (action.type === 'SET_SELECTED_ENGINE') {
    return { ...state, selectedEngine: action.selectedEngine }
  } else if (action.type === 'SET_SHOW_RESPONSE_DIALOG') {
    return { ...state, showResponseDialog: action.showResponseDialog }
  } else if (action.type === 'SET_SHOW_RESPONSE_SCREEN_DIALOG') {
    return { ...state, showResponseScreenDialog: action.showResponseScreenDialog }
  } else if (action.type === 'SET_SHOW_ACTION_DIALOG') {
    return { ...state, showActionDialog: action.showActionDialog }
  } else if (action.type === 'SET_SHOW_DELETE_NODE_DIALOG') {
    return { ...state, showDeleteNodeDialog: action.showDeleteNodeDialog }
  } else if (action.type === 'SET_SHOW_FACEBOOK_SETTINGS_DIALOG') {
    return { ...state, showFacebookSettingsDialog: action.showFacebookSettingsDialog }
  } else if (action.type === 'SET_SHOW_GOOGLE_SETTINGS_DIALOG') {
    return { ...state, showGoogleSettingsDialog: action.showGoogleSettingsDialog }
  } else if (action.type === 'SET_SHOW_BILLING_SETTINGS_DIALOG') {
    return { ...state, showBillingSettingsDialog: action.showBillingSettingsDialog }
  } else if (action.type === 'SET_SHOW_SHARE_DIALOG') {
    return { ...state, showShareDialog: action.showShareDialog }
  } else if (action.type === 'SET_SHOW_NETWORK_ERROR_DIALOG') {
    return { ...state, showNetworkErrorDialog: action.showNetworkErrorDialog }
  } else if (action.type === 'RESET_DIALOGS') {
    return initialState
  }
  return state
}

export { dialogsReducer }